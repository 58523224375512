import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { defineComponent } from '~/scripts/utils/alpine'
import screens from '~/config/screens.json'

export default defineComponent(() => ({
  async init() {
    const { content, contentScroll, logo } = this.$refs
    if (!content || !contentScroll) return

    const html = document.documentElement
    const title = content.querySelector('h3')
    const titles = contentScroll.querySelectorAll('.article-title')
    const bodies = contentScroll.querySelectorAll('.article-body')

    gsap
      .matchMedia()
      // Animations for touch devices
      .add(screens.touch.raw, () => {
        const { contentSticky } = this.$refs
        if (!contentSticky) return

        const defaults: ScrollTrigger.Vars = {
          scroller: contentSticky,
          horizontal: true,
        }

        gsap.to(title, {
          x: -100,
          ease: 'none',
          scrollTrigger: {
            ...defaults,
            trigger: title,
            scrub: true,
            start: 0,
            end: 'right left',
          },
        })

        for (const title of titles) {
          gsap.fromTo(
            title,
            {
              x: 50,
            },
            {
              x: -200,
              ease: 'none',
              scrollTrigger: {
                ...defaults,
                trigger: title,
                scrub: true,
                start: 'left right',
                end: 'right left',
              },
            },
          )
        }

        for (const body of bodies) {
          ScrollTrigger.create({
            ...defaults,
            trigger: body,
            start: 'left 80%',
            end: 'right 10%',
            onUpdate: () => {
              // onEnter not triggered for first elements as they are already in the trigger area
              body.classList.add('is-visible')
            },
            onLeaveBack: () => {
              body.classList.remove('is-visible')
            },
          })
        }
      })

      // Animations for non-touch devices
      .add(screens['not-touch'].raw, () => {
        const scrollTween = gsap.to(contentScroll, {
          x: () => -contentScroll.clientWidth + html.clientWidth,
          ease: 'none',
          scrollTrigger: {
            trigger: content,
            scrub: true,
            start: 'top bottom',
            end: 'bottom top',
            invalidateOnRefresh: true,
          },
        })

        gsap.to(logo, {
          x: () => -contentScroll.clientWidth + html.clientWidth,
          ease: 'none',
          scrollTrigger: {
            containerAnimation: scrollTween,
            trigger: logo,
            scrub: true,
            start: scrollTween.scrollTrigger?.start,
            end: scrollTween.scrollTrigger?.end,
            invalidateOnRefresh: true,
            onToggle: ({ isActive }) => {
              gsap.set(logo, { willChange: isActive ? 'transform' : 'auto' })
            },
          },
        })

        gsap.to(title, {
          x: -200,
          ease: 'none',
          scrollTrigger: {
            containerAnimation: scrollTween,
            trigger: title,
            scrub: true,
            start: scrollTween.scrollTrigger?.start,
            end: 'right left',
          },
        })

        for (const title of titles) {
          gsap.fromTo(
            title,
            {
              x: 50,
            },
            {
              x: -200,
              ease: 'none',
              scrollTrigger: {
                containerAnimation: scrollTween,
                trigger: title,
                scrub: true,
                start: 'left right',
                end: 'right left',
              },
            },
          )
        }

        for (const body of bodies) {
          ScrollTrigger.create({
            containerAnimation: scrollTween,
            trigger: body,
            start: 'left 80%',
            end: 'right 10%',
            onUpdate: () => {
              // onEnter not triggered for first elements as they are already in the trigger area
              body.classList.add('is-visible')
            },
            onLeaveBack: () => {
              body.classList.remove('is-visible')
            },
          })
        }
      })
  },
}))
