import { gsap } from 'gsap'
import { defineComponent } from '~/scripts/utils/alpine'
import { useElementSize } from '~/scripts/composables/useElementSize'
import { useStore } from '~/scripts/composables/useStore'

export default defineComponent(() => ({
  catchphraseSemiBold: false,
  hasBackground: false,
  init() {
    gsap.delayedCall(1, () => {
      this.catchphraseSemiBold = true
    })
    const store = useStore(this)
    useElementSize(this.$el, (size) => {
      store.ui.setHeaderHeight(size.height)
    })

    gsap.to(this.$el.querySelector('.logo'), {
      scale: 1,
      force3D: false, // 3d transform causes blurry svg on Safari
      x: 0,
      y: 0,
      scrollTrigger: {
        start: -1,
        end: '+=200',
        scrub: 0.8,
        invalidateOnRefresh: true,
        fastScrollEnd: true,
        onLeave: () => {
          this.hasBackground = true
        },
        onEnterBack: () => {
          this.hasBackground = false
        },
      },
    })
  },
  openMenu() {
    const store = useStore(this)
    store.ui.setMenuVisible(true)
  },
}))
