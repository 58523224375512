import { easeInOutCubic } from './easing'

/**
 * Scroll to target
 */
export function scrollTo(target: number | string | Element) {
  if (window.lenis) {
    const offset = -(window.$store?.ui.scrollPaddingTop ?? 0)
    window.lenis.scrollTo(target, {
      offset,
      easing: easeInOutCubic,
    })
  } else {
    if (typeof target === 'number') {
      window.scrollTo({ top: target, behavior: 'smooth' })
    } else if (typeof target === 'string') {
      const element = document.querySelector(target)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    } else {
      target.scrollIntoView({ behavior: 'smooth' })
    }
  }
}

export interface ScrollToAnchorOptions {
  pushState?: boolean // Push state to history
  blacklist?: string[] // Blacklist of anchors (e.g. ['#', '#close'])
}

/**
 * Scroll to anchor
 */
export function scrollToAnchor(
  element: Element,
  event?: MouseEvent,
  options?: ScrollToAnchorOptions,
) {
  const pushState = options?.pushState ?? true
  const blacklist = ['#', ...(options?.blacklist ?? [])]

  const hash = element.getAttribute('href')

  if (hash?.startsWith('#') && !blacklist.includes(hash)) {
    const element = document.querySelector(hash)
    if (element) {
      event?.preventDefault()
      scrollTo(element)
      if (pushState) {
        history.pushState(undefined, '', hash)
      }
    }
  }
}

/**
 * Scroll to the next section
 */
export function scrollToNextSection(element: Element) {
  if (element && element.nextElementSibling) {
    scrollTo(element.nextElementSibling)
  }
}

export default { scrollTo, scrollToAnchor, scrollToNextSection }
