/**
 * useSplitText
 * process a text element with <br> tags and wrap each line in a div or span
 * for example <p>Lorem<br>Ipsum</p> becomes <p><span>Lorem</span><span>Ipsum</span></p>
 * @param element html element to process
 * @param wrapper tag name to wrap line
 * @returns element with wrapped lines
 */

export function useSplitText(element: HTMLElement, wrapper: 'div' | 'span') {
  const parts = element.innerHTML.split('<br>')

  element.innerHTML = ''

  for (const part of parts) {
    const div = document.createElement(wrapper)
    div.textContent = part.trim()
    element.append(div)
  }

  return element
}
