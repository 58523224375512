import { defineComponent } from '~/scripts/utils/alpine'
import { useElementMouseMagnet } from '~/scripts/composables/useElementMouseMagnet'

const set = (target: HTMLElement, name: string, value: number) => {
  target.style.setProperty(`--tw-${name}`, `${value.toFixed(2)}px`)
}

export default defineComponent(() => ({
  init() {
    const { content, frame } = this.$refs
    useElementMouseMagnet(this.$root, {
      thresholdMargin: 70,
      initialValues: [
        {
          lambda: 0.06,
          getTarget: ({ distanceX, attracted }) =>
            attracted ? distanceX * 0.3 : 0,
          onUpdate: (value) => set(content, 'translate-x', value.current),
        },
        {
          lambda: 0.06,
          getTarget: ({ distanceY, attracted }) =>
            attracted ? distanceY * 0.3 : 0,
          onUpdate: (value) => set(content, 'translate-y', value.current),
        },
        {
          getTarget: ({ distanceX, attracted }) =>
            attracted ? distanceX * 0.3 : 0,
          onUpdate: (value) => set(frame, 'translate-x', value.current),
        },
        {
          getTarget: ({ distanceY, attracted }) =>
            attracted ? distanceY * 0.3 : 0,
          onUpdate: (value) => set(frame, 'translate-y', value.current),
        },
      ],
    })
  },
}))
