import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { defineComponent } from '~/scripts/utils/alpine'

export default defineComponent(() => ({
  isActive: false,
  init() {
    ScrollTrigger.create({
      trigger: this.$root,
      start: 'top 40%',
      end: 'bottom top',
      onEnter: () => {
        this.isActive = true
      },
      onLeaveBack: () => {
        this.isActive = false
      },
    })
  },
}))
