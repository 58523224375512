import { defineComponent } from '~/scripts/utils/alpine'

/**
 * This allows to load video after the whole page is loaded
 */
export default defineComponent(() => ({
  init() {
    const { video }: { video: HTMLVideoElement } = this.$refs

    if (video.dataset.autoplay) {
      video.play()
    }
  },
}))
