import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { defineComponent } from '~/scripts/utils/alpine'
import { useElementSize } from '~/scripts/composables/useElementSize'
import { useMouse } from '~/scripts/composables/useMouse'
import { Position } from '~/types'
import { getDistance } from '~/scripts/utils/math'
import screens from '~/config/screens.json'

const { interpolate } = gsap.utils

function createImageTrailItem($element: HTMLElement) {
  const $inner = $element.querySelector('img')
  const size = useElementSize($element)

  return {
    $element,
    $inner,
    timeline: undefined as gsap.core.Timeline | undefined,
    size,
    destroy: () => {
      size.destroy()
    },
  }
}

function mapSpeedToSize(speed: number, minSize: number, maxSize: number) {
  const maxSpeed = 200
  return minSize + (maxSize - minSize) * Math.min(speed / maxSpeed, 1)
}

export default defineComponent(() => ({
  canActivate: false,
  isActive: false,
  isTicking: false,
  scrollTrigger: undefined as ScrollTrigger | undefined,
  imageTrailItems: [] as ReturnType<typeof createImageTrailItem>[],
  get totalItems() {
    return this.imageTrailItems.length
  },
  // Index of the upcoming image
  imageIndex: 0,
  // z-index value for the upcoming image
  zIndex: 1,
  // Counter of active images
  activeImagesCount: 0,
  get isIdle() {
    return this.activeImagesCount === 0
  },
  idleTimeout: undefined as ReturnType<typeof setTimeout> | undefined,
  // Mouse distance from the previous trigger required to show the next image
  threshold: 150,
  lastSpawnPosition: undefined as Position | undefined,
  mouse: undefined as ReturnType<typeof useMouse> | undefined,
  ghostMouse: undefined as Position | undefined,
  async init() {
    gsap.matchMedia().add(`${screens['not-touch'].raw}`, () => {
      this.canActivate = true
      this.scrollTrigger = ScrollTrigger.create({
        trigger: this.$root,
        onKill: () => {
          this.isActive = false
        },
        onToggle: ({ isActive }) => {
          this.isActive = isActive
        },
      })
    })

    this.mouse = useMouse({ touch: false })

    const render = this.render.bind(this)
    const start = () => {
      if (!this.isTicking && this.isActive) {
        gsap.ticker.add(render)
        this.isTicking = true
      }

      if (this.idleTimeout) clearTimeout(this.idleTimeout)
      // Stop the render loop if nothing happened after 1s
      // The timeout is cleared on showNextImage()
      this.idleTimeout = setTimeout(() => {
        stop()
      }, 1000)
    }

    const stop = () => {
      if (this.isTicking) {
        gsap.ticker.remove(render)
        this.isTicking = false
        // Reset z-index to avoid endless increment
        this.zIndex = 1
      }
    }

    this.$watch('mouse', (value) => {
      if (value) {
        // Start render loop (if not already) on any mouse move
        start()
      }
    })

    // Stop render loop because all images are hidden
    this.$watch('isIdle', (value) => {
      if (value) {
        stop()
      }
    })

    this.$watch('isActive', async (value) => {
      if (value) {
        if (this.imageTrailItems.length === 0) {
          await this.$nextTick()
          for (const itemElement of this.$root.querySelectorAll('div')) {
            this.imageTrailItems.push(createImageTrailItem(itemElement))
          }
        }
      } else {
        // Stop render loop because the component is outside the viewport
        stop()
      }
    })
  },
  render() {
    if (!this.mouse) return
    const distance = this.lastSpawnPosition
      ? getDistance(
          this.mouse.x,
          this.mouse.y,
          this.lastSpawnPosition.x,
          this.lastSpawnPosition.y,
        )
      : this.threshold

    this.ghostMouse = {
      x: interpolate(this.ghostMouse?.x ?? this.mouse.x, this.mouse.x, 0.1),
      y: interpolate(this.ghostMouse?.y ?? this.mouse.y, this.mouse.y, 0.1),
    }

    if (distance >= this.threshold) {
      this.lastSpawnPosition = { x: this.mouse.x, y: this.mouse.y }
      this.showNextImage()
    }
  },
  showNextImage() {
    if (!this.mouse || !this.ghostMouse || !this.scrollTrigger) return
    if (this.imageTrailItems.length === 0) return
    const speed = getDistance(
      this.mouse.x,
      this.mouse.y,
      this.ghostMouse.x,
      this.ghostMouse.y,
    )
    const { scroller, start } = this.scrollTrigger
    const scrollerHeight =
      'innerHeight' in scroller ? scroller.innerHeight : scroller.clientHeight
    // Distance from the top of the scroller to the top of the root element
    const rootOffsetY = start + scrollerHeight

    // Get next image index
    this.imageIndex = (this.imageIndex + 1) % this.totalItems

    const image = this.imageTrailItems[this.imageIndex]
    const scale = mapSpeedToSize(speed, 0.3, 1)
    const fromY = this.ghostMouse.y - rootOffsetY - image.size.height / 2
    const toY = this.mouse.y - rootOffsetY - image.size.height / 2

    if (fromY < 0 || toY < 0) return

    gsap.killTweensOf([image.$element, image.$inner])

    // Increment z-index for next image
    this.zIndex++

    image.timeline = gsap
      .timeline({
        onStart: () => {
          this.activeImagesCount++
          if (this.idleTimeout) {
            // Reset the timeout since something happened
            clearTimeout(this.idleTimeout)
            this.idleTimeout = undefined
          }
        },
        onComplete: () => {
          this.activeImagesCount--
        },
      })
      .fromTo(
        image.$element,
        {
          autoAlpha: 1,
          scale: 0,
          zIndex: this.zIndex,
          x: this.ghostMouse.x - image.size.width / 2,
          y: fromY,
        },
        {
          duration: 0.8,
          ease: 'power3',
          scale,
          x: this.mouse.x - image.size.width / 2,
          y: toY,
        },
        0,
      )
      .fromTo(
        image.$inner,
        { scale: 2, filter: 'brightness(250%)' },
        {
          duration: 0.8,
          ease: 'power3',
          scale: 1,
          filter: 'brightness(100%)',
        },
        0,
      )
      .to(
        image.$element,
        {
          duration: 0.4,
          ease: 'power3.in',
          autoAlpha: 0,
          scale: 0.2,
        },
        0.45,
      )
  },
}))
