import { defineComponent } from '~/scripts/utils/alpine'
import { useTimer } from '~/scripts/composables/useTimer'
import { useSplitType } from '~/scripts/composables/useSplitType'
import screens from '~/config/screens.json'

function createSlide(target: HTMLElement) {
  let isVisible = false
  let split = undefined as ReturnType<typeof useSplitType> | undefined

  const splitTarget = target.querySelector<HTMLElement>('.split')
  if (splitTarget) {
    split = useSplitType(splitTarget, {
      duration: 700,
      stagger: 50,
      easing: 'cubic-bezier(0.4, 0, 0.1, 1)',
    })
  }

  return {
    target,
    isVisible,
    get height() {
      return target.clientHeight
    },
    enter: async () => {
      isVisible = true
      if (!split) return
      await split.enter([
        {
          transform: 'translate3d(0, 60%, 0)',
          clipPath: 'inset(0 0 100%)',
        },
        {
          transform: 'translate3d(0, 0, 0)',
          clipPath: 'inset(0 0 0)',
        },
      ])
    },
    leave: async () => {
      isVisible = false
      if (!split) return
      await split.leave([
        {
          transform: 'translate3d(0, 0, 0)',
          clipPath: 'inset(0 0 0)',
        },
        {
          transform: 'translate3d(0, -60%, 0)',
          clipPath: 'inset(100% 0 0)',
        },
      ])
    },
  }
}

export default defineComponent(() => ({
  isActive: false,
  carouselIndex: 0,
  slides: [] as ReturnType<typeof createSlide>[],
  async init() {
    const { carousel } = this.$refs
    if (!carousel) return

    const carouselTimer = useTimer(() => this.carouselNext(), {
      delay: 7000,
      autoStart: false,
    })

    this.slides = [...carousel.children].map((child) =>
      createSlide(child as HTMLElement),
    )

    if (this.slides.length === 0) return

    const touchMatchMedia = window.matchMedia(screens.touch.raw)

    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          // Only one slide, just show it and disconnect the observer
          if (this.slides.length === 1) {
            this.slides[0].enter()
            intersectionObserver.disconnect()
          }
          // Multiple slides, start carousel timer
          else {
            carouselTimer.start()
            if (!this.slides[this.carouselIndex].isVisible) {
              this.slides[this.carouselIndex].enter()
            }
          }
        } else {
          carouselTimer.stop()
        }
      },
      { threshold: touchMatchMedia ? 0.2 : 0.8 },
    )

    intersectionObserver.observe(carousel)
  },
  async carouselNext() {
    const { classList, clientHeight, style } = this.$refs.carousel
    const heightTransition = [
      'transition-[height]',
      'duration-500',
      'ease-natural',
    ]
    const nextIndex = (this.carouselIndex + 1) % this.slides.length
    const current = this.slides[this.carouselIndex]
    const next = this.slides[nextIndex]

    // Leave animation
    current.leave()

    // Set carousel's current height
    style.height = clientHeight + 'px'

    // Wait for height transition to finish
    this.$refs.carousel.addEventListener(
      'transitionend',
      () => {
        // Disable transition and reset height
        classList.remove(...heightTransition)
        style.height = ''
        // Set next index
        this.carouselIndex = nextIndex
      },
      { once: true },
    )

    // Enable transition
    classList.add(...heightTransition)

    // Set next height
    style.height = next.height + 'px'

    // Enter animation
    setTimeout(() => {
      next.enter()
    }, 700)
  },
}))
