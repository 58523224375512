/**
 * A timer that calls a callback function every x milliseconds
 * @param callback - function to call every x milliseconds
 * @param options - options
 * @param options.delay - delay in milliseconds
 * @param options.autoStart - start timer on init
 * @returns - timer object
 * @returns .start - start timer
 * @returns .stop - stop timer
 * @returns .time - time in milliseconds since timer started
 * @returns .progress - progress between 0 and 1
 * @example
 * let loops = 0
 *
 * const timer = useTimer(() => {
 *  console.log('tick every 3 seconds')
 *  loops++
 *  if (loops === 3) {
 *    timer.stop()
 *  }
 * }, { delay: 3000, autoStart: false })
 *
 * timer.start()
 * function raf() {
 *   console.log({
 *     time: timer.time,
 *     progress: timer.progress,
 *   })
 *   requestAnimationFrame(raf)
 * }
 * requestAnimationFrame(raf)
 */
export function useTimer(
  callback: (delay?: number) => void = () => {},
  options?: { delay?: number; autoStart?: boolean },
) {
  let interval: number | undefined
  const delay = options?.delay ?? 1000
  const autoStart = options?.autoStart ?? true
  let startTime = Date.now()

  const start = () => {
    if (interval) {
      console.log('start but clear before')
      stop()
    }
    startTime = Date.now()
    interval = setInterval(() => {
      callback(delay)
      startTime = Date.now()
    }, delay)
  }

  const stop = () => {
    clearInterval(interval)
    interval = undefined
  }

  if (autoStart) {
    start()
  }

  return {
    start,
    stop,
    get time() {
      return Date.now() - startTime
    },
    get progress() {
      return this.time / delay
    },
  }
}
