import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { defineComponent } from '~/scripts/utils/alpine'
import screens from '~/config/screens.json'

export default defineComponent(() => ({
  isActive: false,
  init() {
    // @ts-expect-error this.scrollTween comes from a parent component with horizontal scroll (e.g.: SectionCaseContent)
    const scrollTween: gsap.core.Animation | undefined = this.scrollTween

    const options: ScrollTrigger.StaticVars = scrollTween
      ? { containerAnimation: scrollTween }
      : { scroller: this.$refs.contentSticky }

    ScrollTrigger.create({
      ...options,
      trigger: this.$root,
      horizontal: true,
      start: 'left center',
      end: 'right center',
      scrub: true,
      onToggle: ({ isActive }) => {
        this.isActive = isActive
      },
    })

    gsap.matchMedia().add(screens['not-touch'].raw, () => {
      const inner = this.$root.querySelector<HTMLElement>('.sticky')
      if (inner && scrollTween) {
        gsap.to(inner, {
          x: () => this.$root.clientWidth - inner.clientWidth,
          ease: 'none',
          scrollTrigger: {
            containerAnimation: scrollTween,
            trigger: this.$root,
            horizontal: true,
            start: 'left center',
            end: 'right center',
            scrub: true,
            invalidateOnRefresh: true,
          },
        })
      }
    })
  },
}))
