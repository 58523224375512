import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { defineComponent } from '~/scripts/utils/alpine'
import { useStore } from '~/scripts/composables/useStore'

/**
 * Marquee
 * @param {Object} parameters
 * @param {number} [parameters.scrollAmount=180] - The amount of pixels to scroll per second
 */
export default defineComponent((parameters?: { scrollAmount: number }) => ({
  isPlaying: false,
  duration: 5, // seconds
  init() {
    const firstChild = this.$el.querySelector<HTMLElement>(
      'div>span:first-child',
    )
    if (!firstChild) return

    const store = useStore(this)

    ScrollTrigger.create({
      trigger: this.$el,
      start: 'top bottom',
      end: () => `bottom top+=${store.ui.offsetTop}px`,
      onToggle: ({ isActive }) => {
        this.isPlaying = isActive
      },
      onRefresh: () => {
        this.duration = this.getDuration(firstChild)
      },
    })
  },
  getDuration(element: HTMLElement) {
    const { scrollAmount } = { scrollAmount: 200, ...parameters }
    return element.offsetWidth / scrollAmount
  },
}))
