import Alpine from 'alpinejs'
import { Position } from '~/types'

export type UseMouseCoordType = 'page' | 'client' | 'screen' | 'movement'
export type UseMouseSourceType = 'mouse' | 'touch' | undefined
export type UseMouseEventExtractor = (
  event: MouseEvent | Touch,
) => [x: number, y: number] | null | undefined

export interface UseMouseOptions {
  /**
   * Mouse position based by page, client, screen, or relative to previous position
   *
   * @default 'page'
   */
  type?: UseMouseCoordType | UseMouseEventExtractor

  /**
   * Listen events on `target` element
   *
   * @default 'Window'
   */
  target?: Window | HTMLElement | undefined

  /**
   * Listen to `touchmove` events
   *
   * @default true
   */
  touch?: boolean

  /**
   * Listen to `scroll` events on window, only effective on type `page`
   *
   * @default true
   */
  scroll?: boolean

  /**
   * Reset to initial value when `touchend` event fired
   *
   * @default false
   */
  resetOnTouchEnds?: boolean

  /**
   * Initial values
   *
   * @default { x: 0, y: 0 }
   */
  initialValue?: Position
}

const UseMouseBuiltinExtractors: Record<
  UseMouseCoordType,
  UseMouseEventExtractor
> = {
  page: (event) => [event.pageX, event.pageY],
  client: (event) => [event.clientX, event.clientY],
  screen: (event) => [event.screenX, event.screenY],
  movement: (event) =>
    event instanceof Touch ? undefined : [event.movementX, event.movementY],
} as const

/**
 * Reactive mouse position
 * @param options - Options
 */
export function useMouse(options: UseMouseOptions = {}) {
  const {
    type = 'page',
    touch = true,
    resetOnTouchEnds = false,
    initialValue = { x: 0, y: 0 },
    target = window,
    scroll = true,
  } = options

  let _previousMouseEvent: MouseEvent | undefined
  let _previousMouseEventScrollX: number | undefined
  let _previousMouseEventScrollY: number | undefined

  const mouse = Alpine.reactive({
    previousX: initialValue.x,
    previousY: initialValue.y,
    x: initialValue.x,
    y: initialValue.y,
    sourceType: undefined as UseMouseSourceType,
  })

  const extractor =
    typeof type === 'function' ? type : UseMouseBuiltinExtractors[type]

  const setX = (x: number) => {
    mouse.previousX = mouse.x
    mouse.x = x
  }

  const setY = (y: number) => {
    mouse.previousY = mouse.y
    mouse.y = y
  }

  const reset = () => {
    mouse.x = initialValue.x
    mouse.y = initialValue.y
    mouse.previousX = initialValue.x
    mouse.previousY = initialValue.y
  }

  const mouseHandler = (event: MouseEvent) => {
    const result = extractor(event)
    _previousMouseEventScrollX = window.scrollX
    _previousMouseEventScrollY = window.scrollY
    _previousMouseEvent = event

    if (result) {
      setX(result[0])
      setY(result[1])
      mouse.sourceType = 'mouse'
    }
  }

  const touchHandler = (event: TouchEvent) => {
    if (event.touches.length > 0) {
      const result = extractor(event.touches[0])
      if (result) {
        setX(result[0])
        setY(result[1])
        mouse.sourceType = 'touch'
      }
    }
  }

  const scrollHandler = () => {
    if (!_previousMouseEvent || !window) return
    const result = extractor(_previousMouseEvent)
    if (_previousMouseEvent instanceof MouseEvent && result) {
      setX(result[0] + window.scrollX - _previousMouseEventScrollX!)
      setY(result[1] + window.scrollY - _previousMouseEventScrollY!)
    }
  }

  if (target) {
    const listenerOptions = { passive: true }
    // @ts-expect-error TS seems to struggle with the union type Window | HTMLElement
    target.addEventListener('mousemove', mouseHandler, listenerOptions)
    // @ts-expect-error TS seems to struggle with the union type Window | HTMLElement
    target.addEventListener('dragover', mouseHandler, listenerOptions)
    if (touch && type !== 'movement') {
      // @ts-expect-error TS seems to struggle with the union type Window | HTMLElement
      target.addEventListener('touchstart', touchHandler, listenerOptions)
      // @ts-expect-error TS seems to struggle with the union type Window | HTMLElement
      target.addEventListener('touchmove', touchHandler, listenerOptions)
      if (resetOnTouchEnds)
        target.addEventListener('touchend', reset, listenerOptions)
    }
    if (scroll && type === 'page') {
      window.addEventListener('scroll', scrollHandler, listenerOptions)
    }
  }

  return mouse
}
