import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Observer } from 'gsap/Observer'
import { defineComponent } from '~/scripts/utils/alpine'
import { useStore } from '~/scripts/composables/useStore'
import { scrollTo } from '~/scripts/utils/scroll'

gsap.registerPlugin(Observer, ScrollTrigger)

// To anyone who is reading this, I'm sorry for this code, I was in a hurry

export default defineComponent(() => ({
  themes: [],
  index: 0,
  animating: false,
  canEnable: true,

  init() {
    const { content, logo } = this.$refs
    const store = useStore(this)

    this.themes = JSON.parse(this.$root.dataset?.themes || '[]')

    const sections = content.querySelectorAll<HTMLElement>('.js-section')

    // Create a ScrollTrigger Observer
    const observer = ScrollTrigger.observe({
      type: 'wheel,touch',
      wheelSpeed: -1,
      onUp: () => !this.animating && goToSection(this.index + 1, true),
      onDown: () => !this.animating && goToSection(this.index - 1, false),
      tolerance: 100,
      preventDefault: true,
    })

    // Disable observer to start
    observer.disable()

    // Handle the section animation
    const goToSection = (current: number, down: boolean) => {
      this.animating = true
      this.index = current

      // Return on enter or on exit section
      // Note: this part should have been outside of this function
      // The goToSection function have too much dependencies which involve potential side effects
      if (
        (this.index === sections.length && down) ||
        (this.index === -1 && !down)
      ) {
        this.canEnable = false
        setTimeout(() => {
          // Prevent immediate scrollTrigger enter
          this.canEnable = true
        }, 500)
        store.ui.unlockScrollBy('aboutWe')
        observer.disable()
        return
      }

      const color = this.themes[this.index][2]

      const timeline = gsap.timeline({
        onComplete: () => {
          this.animating = false
        },
      })

      timeline.to(
        logo,
        {
          color,
          duration: 0.75,
          y: down ? '-=10' : '+=10',
        },
        0,
      )

      // On scroll down
      if (down) {
        for (const [index, section] of sections.entries()) {
          // Clip each section before the current section and the active one as well
          if (index <= this.index) {
            timeline.to(
              section,
              {
                '--clip-path': '0% 0%, 100% 0%, 100% 100%, 0% 100%',
                duration: 0.75,
                ease: 'power2.inOut',
              },
              0,
            )
          }
        }
      }

      // On scroll up
      if (!down) {
        for (const [index, section] of sections.entries()) {
          // Clip each section before the current one
          if (index > this.index) {
            timeline.to(
              section,
              {
                '--clip-path': '0% 100%, 100% 100%, 100% 100%, 0% 100%',
                duration: 0.75,
                ease: 'power3.inOut',
              },
              0,
            )
          }
        }
      }
    }

    // Pin section, enable observer on enter and on enterBack callback
    ScrollTrigger.create({
      trigger: this.$root,
      pin: true,
      start: 'bottom bottom',
      onEnter: () => {
        if (!this.canEnable) return
        store.ui.lockScrollBy('aboutWe')
        observer.enable()
        goToSection(0, true)
      },
      onEnterBack: () => {
        if (!this.canEnable) return
        store.ui.lockScrollBy('aboutWe')
        observer.enable()
        goToSection(sections.length - 1, false)
      },
    })

    // Support for anchors
    for (const anchor of this.$root.querySelectorAll<HTMLAnchorElement>(
      'a[href*="#"]:not([href$="#"])',
    )) {
      anchor.addEventListener('click', (event) => {
        const hash = anchor.getAttribute('href')
        if (hash?.startsWith('#')) {
          const target = document.querySelector<HTMLElement>(hash)
          if (target) {
            event.preventDefault()
            observer.disable()
            store.ui.unlockScrollBy('aboutWe')

            // Reset section
            goToSection(0, false)

            setTimeout(() => {
              scrollTo(target)
              history.pushState(undefined, '', hash)
            }, 100)
          }
        }
      })
    }
  },
}))
