import Alpine from 'alpinejs'
import ui from './ui'

export default function register() {
  Alpine.store('ui', ui)
}

declare global {
  type Store = {
    ui: typeof ui
  }

  interface Window {
    $store?: Store
  }
}
