import { gsap } from 'gsap'
import { defineComponent } from '~/scripts/utils/alpine'

export default defineComponent(() => ({
  eggBadgeVisible: false,
  init() {
    // @ts-expect-error this.scrollTween comes from the parent component (SectionCaseContent)
    const scrollTween: gsap.core.Animation | undefined = this.scrollTween

    const options: ScrollTrigger.StaticVars = scrollTween
      ? { containerAnimation: scrollTween }
      : { scroller: this.$refs.contentSticky }

    gsap.to(this.$refs.deco, {
      x: () =>
        this.$root.clientWidth - window.innerWidth - window.innerWidth / 4,
      ease: 'none',
      scrollTrigger: {
        ...options,
        trigger: this.$root,
        horizontal: true,
        start: 'left left',
        end: 'right right',
        scrub: true,
        invalidateOnRefresh: true,
        onToggle: ({ isActive }) => {
          this.eggBadgeVisible = isActive
        },
      },
    })

    const articles = this.$root.querySelectorAll<HTMLElement>('article')
    for (const article of articles) {
      const inner = article.children[0]
      const tl = gsap.timeline({
        defaults: { ease: 'none' },
        scrollTrigger: {
          ...options,
          trigger: article,
          horizontal: true,
          start: 'left right',
          end: 'right left',
          scrub: true,
        },
      })
      tl.fromTo(inner, { xPercent: -50 }, { xPercent: 50, duration: 1 })
      tl.fromTo(inner, { opacity: 0 }, { opacity: 1, duration: 0.1 }, 0.4)
      tl.to(inner, { opacity: 0, duration: 0.1 }, 0.8)
    }
  },
}))
