import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { defineComponent } from '~/scripts/utils/alpine'
import { useStore } from '~/scripts/composables/useStore'
import { useTimer } from '~/scripts/composables/useTimer'
import { useTeamApi } from '~/scripts/api/team'

export default defineComponent(() => ({
  isActive: false,
  teamMembers: [] as TeamMember[],
  carouselIndex: 0,
  async init() {
    const carouselTimer = useTimer(() => this.carouselNext(), {
      delay: 500,
      autoStart: false,
    })

    const store = useStore(this)

    const { carousel } = this.$refs
    if (carousel) {
      const intersectionObserver = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          carouselTimer.start()
        } else {
          carouselTimer.stop()
        }
      })

      intersectionObserver.observe(carousel)
    }

    ScrollTrigger.create({
      trigger: this.$el,
      start: 'top center',
      end: 'bottom center',
      onToggle: ({ isActive }) => {
        this.isActive = isActive
        store.ui.setTheme(isActive ? 'ecru-white-100' : 'default')
      },
    })

    try {
      const teamApi = useTeamApi()
      const members = await teamApi.getTeamMembers()
      // Only keep members with an image
      this.teamMembers = members.filter((member) => member.image_main)
    } catch {}
  },
  carouselNext() {
    this.carouselIndex = (this.carouselIndex + 1) % this.teamMembers.length
  },
}))
