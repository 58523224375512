import Alpine from 'alpinejs'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { debounce } from 'radash'
import { useMouse } from '~/scripts/composables/useMouse'
import { isAtTarget } from '~/scripts/utils/math'
import screens from '~/config/screens.json'

interface UseElementMouseMagnetAnimatedValue {
  /**
   * The value at the previous frame
   */
  previous: number

  /**
   * The value at the current frame
   */
  current: number

  /**
   * The target value
   */
  target: number

  /**
   * The lambda value used to interpolate the value
   */
  lambda: number

  /**
   * The precision used to check if the value is at target
   */
  precision?: number

  /**
   * The function used to get the target value
   */
  getTarget: (data: {
    distanceX: number
    distanceY: number
    attracted: boolean
  }) => number

  /**
   * The function called when the value is updated (every frame)
   */
  onUpdate: (value: UseElementMouseMagnetAnimatedValue) => void

  /**
   * The function called when the value is at target
   */
  onComplete?: (value: UseElementMouseMagnetAnimatedValue) => void
}

interface UseElementMouseMagnetInitialValue
  extends Pick<
    UseElementMouseMagnetAnimatedValue,
    'getTarget' | 'onUpdate' | 'onComplete'
  > {
  /**
   * The initial value
   *
   * @default 0
   */
  value?: number

  /**
   * The lambda value used to interpolate the value
   *
   * @default 0.1
   */
  lambda?: number

  /**
   * The precision used to check if the value is at target
   *
   * @default 0.01
   */
  precision?: number
}

interface UseElementMouseMagnetOptions {
  /**
   * This is where you define the animated values.
   *
   * @default "A simple translation on the x and y axis. Don't forget to add the `transform-gpu` class to the element."
   */
  initialValues?: UseElementMouseMagnetInitialValue[]

  /**
   * The distance from the border of the element to the mouse required to be attracted. Can be a number or an object with x and y values.
   *
   * @default 50
   */
  thresholdMargin?: number | { x: number; y: number }

  /**
   * The lambda value used to interpolate the values. This can be overridden for each value in `initialValues`.
   *
   * @default 0.1
   */
  lambda?: number
}

function createValue({
  value = 0,
  lambda = 0.1,
  precision = 0.01,
  ...initialValue
}: UseElementMouseMagnetInitialValue): UseElementMouseMagnetAnimatedValue {
  return {
    ...initialValue,
    previous: value,
    current: value,
    target: value,
    lambda,
    precision,
  }
}

/**
 * Make the element follow the mouse when it gets close enough
 * @param element - The element to apply the effect on
 * @param options - Options
 */
export function useElementMouseMagnet(
  element: HTMLElement,
  options: UseElementMouseMagnetOptions = {},
) {
  const {
    lambda: defaultLambda = 0.1,
    thresholdMargin = 50,
    initialValues = [
      {
        getTarget: ({ distanceX, attracted }) =>
          attracted ? distanceX * 0.3 : 0,
        onUpdate: (value) => {
          element.style.setProperty(
            '--tw-translate-x',
            `${value.current.toFixed(2)}px`,
          )
        },
      },
      {
        getTarget: ({ distanceY, attracted }) =>
          attracted ? distanceY * 0.3 : 0,
        onUpdate: (value) => {
          element.style.setProperty(
            '--tw-translate-y',
            `${value.current.toFixed(2)}px`,
          )
        },
      },
    ],
  } = options

  const state = Alpine.reactive({
    isActive: false,
    isIdle: true,
    isTicking: false,
    x: { previous: 0, current: 0, target: 0 },
    y: { previous: 0, current: 0, target: 0 },
    values: initialValues.map((initialValue) =>
      createValue({ lambda: defaultLambda, ...initialValue }),
    ),
  })

  let scrollTrigger: ScrollTrigger | undefined
  let idleTimeout: ReturnType<typeof setTimeout> | undefined
  let rect: DOMRect | undefined

  const mouse = useMouse({ touch: false })

  // Distance from the center of the element to the mouse required to be attracted
  const threshold = { x: 0, y: 0 }

  gsap.matchMedia().add(`${screens['not-touch'].raw}`, () => {
    scrollTrigger = ScrollTrigger.create({
      trigger: element,
      onToggle: ({ isActive }) => {
        state.isActive = isActive
        if (isActive) {
          update()
        }
      },
    })
  })

  window.addEventListener(
    'resize',
    debounce({ delay: 200 }, () => {
      if (state.isActive) {
        update()
      }
    }),
  )

  const start = () => {
    if (!state.isTicking && state.isActive) {
      gsap.ticker.add(render)
      state.isTicking = true
    }

    // Stop the render loop if nothing happened after 1s
    // The timeout is cleared on after the mouse gets within the threshold
    if (idleTimeout) clearTimeout(idleTimeout)
    idleTimeout = setTimeout(() => {
      stop()
      idleTimeout = undefined
    }, 1000)
  }

  const stop = () => {
    if (state.isTicking) {
      gsap.ticker.remove(render)
      state.isTicking = false
    }
  }

  Alpine.effect(() => {
    if (!state.isActive || (state.isIdle && !idleTimeout)) {
      stop()
    }
    if (state.isActive && !state.isIdle) {
      start()
    }
    if (mouse.x !== undefined) {
      state.isIdle = false
    }
  })

  const render = () => {
    if (!scrollTrigger || !state.isActive || !rect) return
    const { scroller, start } = scrollTrigger
    const scrollerHeight =
      'innerHeight' in scroller ? scroller.innerHeight : scroller.clientHeight
    // Distance from the top of the scroller to the top of the root element
    const elementY = start + scrollerHeight + rect.height / 2
    const elementX = rect.left + rect.width / 2

    const distanceX = mouse.x - elementX
    const distanceY = mouse.y - elementY
    const attracted =
      Math.abs(distanceX) < threshold.x && Math.abs(distanceY) < threshold.y

    if (attracted && idleTimeout) {
      // Reset the timeout since something happened
      clearTimeout(idleTimeout)
      idleTimeout = undefined
    }

    for (const value of state.values) {
      value.target = value.getTarget({ distanceX, distanceY, attracted })
    }

    const results = state.values.map((value) => {
      if (value.target === value.current) return true

      value.previous = value.current

      const progress = 1 - Math.exp(-value.lambda * gsap.ticker.deltaRatio())
      value.current = gsap.utils.interpolate(
        value.current,
        value.target,
        progress,
      )

      if (isAtTarget(value.current, value.target, value.precision)) {
        value.current = value.target
      }
      value.onUpdate(value)

      if (value.target !== value.current) {
        value.onComplete?.(value)
      }

      return value.target === value.current
    })

    if (!results.some((value) => !value)) {
      // All values are at target
      state.isIdle = true
    }
  }

  const update = () => {
    rect = element.getBoundingClientRect()
    const marginX = typeof thresholdMargin === 'number' ? thresholdMargin : 0
    const marginY = typeof thresholdMargin === 'number' ? thresholdMargin : 0
    threshold.x = rect.width / 2 + marginX
    threshold.y = rect.height / 2 + marginY
  }

  return state
}
