import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { defineComponent } from '~/scripts/utils/alpine'
import { useSplitText } from '~/scripts/composables/useSplitText'

gsap.registerPlugin(ScrollTrigger)

export default defineComponent(() => ({
  init() {
    const title = this.$root.querySelector<HTMLElement>(
      '.title-care',
    ) as HTMLElement

    const parts = useSplitText(title, 'div').querySelectorAll<HTMLElement>(
      'div',
    )

    gsap.fromTo(
      parts,
      {
        y: 200,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.8,
        ease: 'power3.out',
        scrollTrigger: {
          trigger: title,
          start: 'top 70%',
        },
        stagger: 0.5,
      },
    )
  },
}))
