import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { defineComponent } from '~/scripts/utils/alpine'

gsap.registerPlugin(ScrollTrigger)

export default defineComponent(() => ({
  init() {
    const slides = this.$root.querySelectorAll<HTMLElement>('.js-slide')

    for (const [index, slide] of slides.entries()) {
      const child = slide.children[0] as HTMLElement

      const offset =
        (child.offsetHeight / 2) *
        gsap.utils.mapRange(0, slides.length - 1, -1, 1, index)

      const animation = gsap.to(slide, {
        y: -child.offsetHeight / 2,
        ease: 'none',
      })

      ScrollTrigger.create({
        trigger: slide,
        animation,
        scrub: true,
        start: () => `top+=${child.offsetHeight / 2 - offset} center`,
        endTrigger: this.$root,
        end: () => `bottom bottom-=${child.offsetHeight / 2 + offset}`,
        pin: true,
        pinSpacing: false,
        id: `section-about-move-${index}`,
        invalidateOnRefresh: true,
      })
    }
  },
}))
