import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { defineComponent } from '~/scripts/utils/alpine'
import screens from '~/config/screens.json'
import { useStore } from '~/scripts/composables/useStore'
import { useElementSize } from '~/scripts/composables/useElementSize'
import { Theme } from '../store/ui'
import colors from '~/config/colors.json'

export default defineComponent(() => ({
  contentScrollSize: undefined as ReturnType<typeof useElementSize> | undefined,
  scrollTween: undefined as gsap.core.Animation | undefined,
  init() {
    const { content, contentScroll } = this.$refs
    if (!content || !contentScroll) return

    const html = document.documentElement
    const sections = this.$root.querySelectorAll<HTMLElement>('[data-theme]')

    this.contentScrollSize = useElementSize(contentScroll)
    const store = useStore(this)

    // Snap to the section for touch devices
    const touchMatchMedia = window.matchMedia(screens.touch.raw)
    const updateSnapConfig = () => {
      if (touchMatchMedia.matches) {
        html.classList.add('snap-y', 'snap-proximity')
        this.$root.classList.add('snap-start')
      } else {
        html.classList.remove('snap-y', 'snap-proximity')
        this.$root.classList.remove('snap-start')
      }
    }
    updateSnapConfig()
    touchMatchMedia.addEventListener('change', updateSnapConfig)

    gsap
      .matchMedia()
      // Animations for touch devices
      .add(screens.touch.raw, () => {
        const { contentSticky } = this.$refs
        if (!contentSticky) return

        for (const section of sections) {
          const { theme } = section.dataset
          if (theme && store.ui.isValidTheme(theme)) {
            ScrollTrigger.create({
              scroller: contentSticky,
              horizontal: true,
              trigger: section,
              start: 'left center',
              end: 'right center',
              onToggle: ({ isActive }) => {
                if (isActive) {
                  // store.ui.setTheme(theme)

                  // Perhaps not the best way to do it
                  switch (theme) {
                    case Theme.Sail300: {
                      this.$root.style.setProperty(
                        'background-color',
                        colors.sail[300],
                      )
                      this.$root.style.setProperty(
                        'border-color',
                        colors.sail[400] + '55',
                      )
                      this.$root.style.removeProperty('color')
                      this.$root.style.removeProperty('--text-color-2')
                      break
                    }
                    case Theme.EcruWhite100: {
                      this.$root.style.setProperty(
                        'background-color',
                        colors['ecru-white'][100],
                      )
                      this.$root.style.setProperty(
                        'border-color',
                        colors['ecru-white'][200] + '55',
                      )
                      this.$root.style.removeProperty('color')
                      this.$root.style.removeProperty('--text-color-2')
                      break
                    }
                    case Theme.Thunder950: {
                      this.$root.style.setProperty(
                        'background-color',
                        colors.thunder[950],
                      )
                      this.$root.style.setProperty(
                        'border-color',
                        colors.thunder[900],
                      )
                      this.$root.style.setProperty('color', colors.sail[300])
                      this.$root.style.setProperty('--text-color-2', '#fff')
                      break
                    }
                    default: {
                      this.$root.style.removeProperty('background-color')
                      this.$root.style.removeProperty('border-color')
                      this.$root.style.removeProperty('color')
                      this.$root.style.removeProperty('--text-color-2')
                    }
                  }
                }
              },
            })
          }
        }
      })

      // Animations for non-touch devices
      .add(screens['not-touch'].raw, () => {
        this.scrollTween = gsap.to(contentScroll, {
          x: () => -contentScroll.clientWidth + html.clientWidth,
          ease: 'none',
          scrollTrigger: {
            trigger: content,
            scrub: true,
            start: 'top bottom',
            // 'bottom bottom-=150px' won't work here because the fake height
            // is not rendered yet (probably on nextTick). No biggie, this is
            // equivalent.
            end: () => `+=${this.contentScrollSize!.width + 150}px`,
            invalidateOnRefresh: true,
          },
        })

        for (const section of sections) {
          const { theme } = section.dataset
          if (theme && store.ui.isValidTheme(theme)) {
            ScrollTrigger.create({
              containerAnimation: this.scrollTween,
              trigger: section,
              start: 'left center',
              end: 'right center',
              onToggle: ({ isActive }) => {
                if (isActive) {
                  store.ui.setTheme(theme)
                }
              },
            })
          }
        }
      })
  },
}))
