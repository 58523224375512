import { gsap } from 'gsap'
import { defineComponent } from '~/scripts/utils/alpine'
import { debounce } from 'radash'

interface CoverItem extends HTMLAnchorElement {
  removeCoverMaskAnimationListener: () => void
}

export default defineComponent(() => ({
  covers: [] as { slug: string; src: string }[],
  init() {
    this.$watch('$store.ui.menuVisible', (value) => {
      if (value) {
        this.onEnter()
      } else {
        this.onLeave()
      }
    })
  },
  onEnter() {
    const { menuOverlay1, menuOverlay2, menuContent, menuClose, menuFoot } =
      this.$refs
    const tl = gsap.timeline()
    tl.fromTo(
      [menuOverlay1, menuOverlay2],
      { x: '100%' },
      { x: '-100%', duration: 1, ease: 'power3.inOut', stagger: 0.1 },
    )
      // Hide from start
      .set(menuContent, { autoAlpha: 0, x: 0 }, 0)
      // Show at half of the overlays' transition time because then, they're full-screen (x: 0)
      .set(menuContent, { autoAlpha: 1 }, 0.5)
      .from(
        menuContent.querySelectorAll('[role="navigation"] li'),
        {
          x: '30vw',
          scaleX: 1.2,
          transformOrigin: 'left',
          opacity: 0,
          duration: 2,
          ease: 'power2.inOut',
          stagger: 0.05,
        },
        -0.5,
      )
      .from(
        menuFoot.querySelectorAll('span,li'),
        {
          opacity: 0,
          y: 50,
          duration: 1.2,
          ease: 'power2.inOut',
          stagger: 0.08,
        },
        '-=1',
      )
      .from(
        menuClose,
        {
          scale: 0,
          duration: 0.4,
          ease: 'power2.inOut',
        },
        '-=1',
      )

    this.initCoverMaskAnimation()
  },
  onLeave() {
    const { menuOverlay1, menuOverlay2, menuContent } = this.$refs
    const tl = gsap.timeline()

    tl.to(menuContent, {
      x: 100,
      duration: 0.25,
      ease: 'power2.in',
      stagger: 0.025,
      onComplete: () => {
        gsap.set(menuContent, { autoAlpha: 0 })
      },
    }).fromTo(
      [menuOverlay1, menuOverlay2],
      { x: '-100%' },
      {
        x: '100%',
        duration: 0.5,
        ease: 'power3.inOut',
        stagger: { each: 0.05, from: 'end' },
      },
      0,
    )

    if (menuContent.parentElement) {
      // Tells the alpine transition how long to wait before hiding the element
      menuContent.parentElement.style.setProperty(
        '--visibility-delay',
        tl.totalDuration() + 's',
      )
    }

    this.removeCoverMaskAnimation()
  },
  getCoverLinkItems() {
    const { menuContent } = this.$refs

    const items = menuContent.querySelectorAll<CoverItem>(
      '[role="navigation"] li > a[data-image]',
    )

    return [...items]
  },
  // replace the background in the G svg aside the navigation menu
  initCoverMaskAnimation() {
    // get cover images from links data-image attributes
    this.covers = this.getCoverLinkItems().map((item) => {
      return {
        slug: item.getAttribute('href')!,
        src: item.dataset.image!,
      }
    })

    // add event listener to each cover link
    let activeIndex = -1 // currently displayed, -1 at start
    const setActiveCoverImage = debounce(
      { delay: 250 },

      (targetIndex: number, immediate: boolean = false) => {
        if (activeIndex === targetIndex) {
          return
        }

        const tween = gsap.timeline()

        tween.set(`#menuImage > img:nth-of-type(${targetIndex + 1})`, {
          zIndex: 2,
        })
        tween.fromTo(
          `#menuImage > img:nth-of-type(${targetIndex + 1})`,
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
            duration: immediate ? 0 : 0.8,
            ease: 'power3.inOut',
          },
          0,
        )
        tween.set(`#menuImage > img:nth-of-type(${targetIndex + 1})`, {
          zIndex: 1,
        })
        tween.set(`#menuImage > img:not(:nth-of-type(${targetIndex + 1}))`, {
          zIndex: 0,
          autoAlpha: 0,
        })

        activeIndex = targetIndex
      },
    )

    // attach listener
    const items = this.getCoverLinkItems()
    for (const [index, item] of items.entries()) {
      const onMouseOver = () => setActiveCoverImage(index)
      item.addEventListener('mouseover', onMouseOver)

      item.removeCoverMaskAnimationListener = () => {
        item.removeEventListener('mouseover', onMouseOver)
      }
    }

    // set the cover at start for the current page
    const { menuContent } = this.$refs
    const currentItem = menuContent.querySelector<CoverItem>(
      '[role="navigation"] li.current-menu-item > a[data-image]',
    )

    const targetIndex = items.indexOf(currentItem!)
    setActiveCoverImage(targetIndex, true)
  },
  removeCoverMaskAnimation() {
    const items = this.getCoverLinkItems()
    for (const item of items) {
      item.removeCoverMaskAnimationListener()
    }
  },
}))
