import { defineComponent } from '~/scripts/utils/alpine'
import { nanoid } from 'nanoid'
import { gsap } from 'gsap'
import { Flip } from 'gsap/Flip'

gsap.registerPlugin(Flip)

export default defineComponent(() => ({
  zoomed: false,
  init() {},
  enter() {
    this.zoomed = true
    const image = this.$el as HTMLImageElement
    const newImage = document.createElement('img')

    // Set flip id and save state
    const id = nanoid()
    image.dataset.flipId = id
    newImage.dataset.flipId = id
    const state = Flip.getState(image)

    // Set image attributes
    newImage.src = image.src
    newImage.srcset = image.srcset
    newImage.sizes = image.sizes
    newImage.width = image.naturalWidth
    newImage.height = image.naturalHeight
    newImage.classList.add('object-cover', 'absolute', 'inset-0', 'm-auto')
    newImage.style.aspectRatio = `${image.naturalWidth}/${image.naturalHeight}`

    // Remove old image
    this.$refs.modal.querySelector('img')?.remove()
    // Add new image
    this.$refs.modal.append(newImage)

    Flip.from(state, {
      targets: newImage,
      duration: 0.6,
      ease: 'power3.inOut',
      onStart: () => {
        // Hide image in the grid
        gsap.to(image, {
          opacity: 0,
          duration: 0.3,
          ease: 'power3.inOut',
        })
      },
    })
  },
  leave() {
    const image = this.$el.querySelector('img')!
    const target = this.$root.querySelector<HTMLImageElement>(
      `[data-flip-id="${image.dataset.flipId}"]`,
    )!

    Flip.killFlipsOf(image)
    Flip.fit(image, target, {
      duration: 0.2,
      ease: 'power1.inOut',
      scale: true,
      absolute: true,
      opacity: 0,
      onStart: () => {
        // Show image in the grid
        gsap.to(target, {
          opacity: 1,
          duration: 0.2,
          ease: 'power1.inOut',
        })
      },
    })

    this.zoomed = false
  },
}))
