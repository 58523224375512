import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { defineComponent } from '~/scripts/utils/alpine'

export default defineComponent((manual = false) => ({
  isVisible: false,
  timeline: undefined as gsap.core.Timeline | undefined,
  init() {
    if (manual === false) {
      ScrollTrigger.create({
        trigger: this.$root.parentElement,
        start: 'top 70%',
        end: 'bottom top',
        toggleActions: 'play none none reverse',
        animation: this.getTimeline(),
      })
    } else {
      this.timeline = this.getTimeline()
      this.$watch('isVisible', (value) => {
        if (this.timeline) {
          if (value) {
            this.timeline.timeScale(1).play()
          } else {
            this.timeline.timeScale(2).reverse()
          }
        }
      })
    }
  },
  getTimeline() {
    return gsap
      .timeline({
        paused: true,
        defaults: { duration: 1, ease: 'power3' },
      })
      .from(this.$root, {
        scale: 0,
        duration: 0.5,
        ease: 'back.out(2)',
      })
      .from(
        this.$root,
        {
          rotate: -180,
          duration: 0.5,
        },
        0,
      )
      .from(this.$refs.e, { x: -40, y: 10 }, 0)
      .from(this.$refs.g1, { x: -10, y: -40 }, 0.1)
      .from(this.$refs.g2, { x: 20, y: 40 }, 0.2)
  },
}))
