import type { AlpineComponent, DirectiveCallback } from 'alpinejs'

/**
 * Define an Alpine component (wraps the function with Alpine's internals)
 */
export const defineComponent = <P, T>(
  function_: (parameters: P) => AlpineComponent<T>,
) => function_

/**
 * Define an Alpine directive (wraps the function with Alpine's internals)
 */
export const defineDirective = (function_: DirectiveCallback) => function_

export default { defineComponent, defineDirective }
