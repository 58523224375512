import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Alpine from 'alpinejs'
import { defineComponent } from '~/scripts/utils/alpine'
import { useTeamApi } from '~/scripts/api/team'
import { wait } from '~/scripts/utils/async'
import { useElementSize } from '~/scripts/composables/useElementSize'

export default defineComponent(() => ({
  mapVisible: false,
  currentPlace: -1,
  teamMember: undefined as TeamMember | undefined,
  teamMemberLoading: false,
  sidebarWidth: 0,
  markerSize: 10, // px
  get sidebarVisible() {
    return (
      !!this.teamMember && !this.teamMemberLoading && this.currentPlace !== -1
    )
  },
  get transform() {
    if (this.teamMember && this.currentPlace !== -1) {
      const { x, y } = this.teamMember.location
      if (x === '0' && y === '0') return ''

      const { markerSize, sidebarWidth } = this
      const tX = `calc(50% - ${x}% - ${markerSize / 2}px)`
      const tY = `calc(50% - ${y}% - ${markerSize / 2}px)`
      const offsetX = -sidebarWidth / 2
      return `translateX(${offsetX}px) scale(2) translate(${tX}, ${tY})`
    }
    return ''
  },
  async init() {
    const teamApi = useTeamApi()

    // Sidebar width
    const sidebarSize = useElementSize(this.$refs.mapSidebar)
    Alpine.effect(() => {
      this.sidebarWidth = sidebarSize.width
    })

    this.$watch('mapVisible', (value) => {
      if (value) {
        this.onEnter()
      } else {
        this.onLeave()
      }
    })

    this.$watch('currentPlace', async (value) => {
      if (value === -1) {
        await wait(500)
        // Remove old team member once the sidebar is hidden
        this.teamMember = undefined
      } else {
        try {
          this.teamMemberLoading = true
          const member = await teamApi.getTeamMemberByCountry(this.currentPlace)

          if (Object.keys(member).length === 0) {
            this.teamMember = undefined
            return
          }

          this.teamMember = member
        } catch {
          this.teamMember = undefined
        } finally {
          this.teamMemberLoading = false
        }
      }
    })

    setTimeout(() => {
      ScrollTrigger.create({
        trigger: this.$refs.mapContainer,
        start: 'top center',
        once: true,
        onEnter: () => {
          this.mapVisible = true
        },
      })
    }, 300)
  },
  async onEnter() {
    const { mapContainer } = this.$refs

    gsap.killTweensOf(mapContainer)

    const tl = gsap.timeline()
    tl.fromTo(
      mapContainer,
      { scale: 0.5, y: 200, autoAlpha: 0 },
      {
        scale: 1,
        y: 0,
        autoAlpha: 1,
        duration: 2,
        ease: 'power4.out',
      },
    ).fromTo(
      mapContainer.querySelectorAll('.marker'),
      { scale: 2, autoAlpha: 0 },
      {
        scale: 1,
        stagger: {
          each: 0.1,
          from: 'random',
          onStart() {
            // @ts-expect-error this.targets retrieves the list of targets per stagger, here it's one
            const marker = this.targets()[0]
            gsap.to(marker, {
              autoAlpha: 1,
              duration: 0.3,
              ease: 'power3.inOut',
            })
          },
          onComplete() {
            // @ts-expect-error this.targets retrieves the list of targets per stagger, here it's one
            const marker = this.targets()[0]
            const ping = marker.querySelector<HTMLElement>('.animate-ping')
            if (ping) {
              ping.classList.remove('hidden')
            }
          },
        },
        duration: 1,
        ease: 'power3.inOut',
      },
      '>-1',
    )

    const formItems = this.$root.querySelectorAll('form > .invisible')
    if (formItems.length > 0) {
      tl.fromTo(
        formItems,
        { autoAlpha: 0, y: 50 },
        {
          autoAlpha: 1,
          y: 0,
          duration: 1,
          stagger: 0.05,
          ease: 'power3.out',
        },
        0,
      )
    }
  },
  onLeave() {
    const { mapContainer } = this.$refs

    gsap.killTweensOf(mapContainer)

    const tl = gsap.timeline()
    tl.to(mapContainer, {
      autoAlpha: 0,
      duration: 1,
      ease: 'power3.inOut',
    })
  },
}))
