import Alpine from 'alpinejs'
import imageIn from './x-image-in'
import recaptcha from './x-recaptcha'
import scrollTo from './x-scroll-to'
import selectAutosize from './x-select-autosize'

export default function register() {
  Alpine.directive('image-in', imageIn)
  Alpine.directive('recaptcha', recaptcha)
  Alpine.directive('scroll-to', scrollTo)
  Alpine.directive('select-autosize', selectAutosize)
}
