import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { shuffle } from 'radash'
import { defineComponent } from '~/scripts/utils/alpine'
import screens from '~/config/screens.json'

function getSequence(element: Element, options?: { excludeHidden?: boolean }) {
  let children = gsap.utils.toArray<HTMLElement>(element.children)
  if (options?.excludeHidden) {
    children = children.filter((child) => {
      return getComputedStyle(child).display !== 'none'
    })
  }
  return shuffle(children.map((_, index) => index))
}

function getDelay() {
  return gsap.utils.random(0.5, 1.5)
}

export default defineComponent(() => ({
  isPlaying: false,
  gridItems: [] as HTMLElement[],
  gridSequence: [] as number[],
  gridItemSequence: [] as number[],
  index: 0,
  get current() {
    return gsap.utils.wrap(this.gridSequence, this.index)
  },
  init() {
    this.gridSequence = getSequence(this.$el, { excludeHidden: true })
    this.gridItemSequence = getSequence(this.$el.children[0])

    // The number of grid items change on lg+ screens so we need to refresh the sequence
    window
      .matchMedia(`(min-width: ${screens.lg})`)
      .addEventListener('change', () => {
        this.index = 0
        this.gridSequence = getSequence(this.$el, { excludeHidden: true })
      })

    this.$watch('isPlaying', (value) => {
      if (value) {
        this.next()
      }
    })

    ScrollTrigger.create({
      trigger: this.$el,
      start: 'top bottom',
      end: 'bottom top',
      onToggle: ({ isActive }) => {
        this.isPlaying = isActive
      },
    })
  },
  next() {
    if (this.isPlaying === false) return

    if (this.index === this.gridSequence.length - 1) {
      // Shuffle the sequence
      this.index = 0
      this.gridSequence = shuffle(this.gridSequence)
    } else {
      this.index += 1
    }
    gsap.delayedCall(getDelay(), () => {
      this.next()
    })
  },
}))
