import Alpine from 'alpinejs'

import BeforeAfter from './BeforeAfter'
import Button from './Button'
import CaseDescriptions from './CaseDescriptions'
import EggBadge from './EggBadge'
import FullscreenGallery from './FullscreenGallery'
import GridSlideshow from './GridSlideshow'
import GridSlideshowItem from './GridSlideshowItem'
import HelperBreakpoints from './HelperBreakpoints'
import Hero from './Hero'
import ImageTrail from './ImageTrail'
import LazyLoadVideo from './LazyLoadVideo'
import Map from './Map'
import Marquee from './Marquee'
import SectionAboutMove from './SectionAboutMove'
import SectionAboutCare from './SectionAboutCare'
import SectionAboutWe from './SectionAboutWe'
import SectionCaseContent from './SectionCaseContent'
import SectionCaseTestimonials from './SectionCaseTestimonials'
import SectionHomeEsg from './SectionHomeEsg'
import SectionHomeObjectives from './SectionHomeObjectives'
import SectionHomeTeam from './SectionHomeTeam'
import SectionHomeWho from './SectionHomeWho'
import SectionTeamNumbers from './SectionTeamNumbers'
import SiteHeader from './SiteHeader'
import SiteMenu from './SiteMenu'
import StickyCaption from './StickyCaption'

export default function register() {
  // UI components
  Alpine.data('Button', Button)
  Alpine.data('HelperBreakpoints', HelperBreakpoints)
  Alpine.data('SiteHeader', SiteHeader)
  Alpine.data('SiteMenu', SiteMenu)
  Alpine.data('Hero', Hero)

  // @ts-expect-error - Alpine doesn't know about the parameters argument
  Alpine.data('Marquee', Marquee)
  Alpine.data('GridSlideshow', GridSlideshow)
  Alpine.data('GridSlideshowItem', GridSlideshowItem)
  Alpine.data('EggBadge', EggBadge)
  Alpine.data('ImageTrail', ImageTrail)
  Alpine.data('Map', Map)
  Alpine.data('CaseDescriptions', CaseDescriptions)
  Alpine.data('BeforeAfter', BeforeAfter)
  Alpine.data('FullscreenGallery', FullscreenGallery)
  Alpine.data('StickyCaption', StickyCaption)
  Alpine.data('LazyLoadVideo', LazyLoadVideo)

  // Home sections
  Alpine.data('SectionHomeObjectives', SectionHomeObjectives)
  Alpine.data('SectionHomeWho', SectionHomeWho)
  Alpine.data('SectionHomeEsg', SectionHomeEsg)
  Alpine.data('SectionHomeTeam', SectionHomeTeam)

  // Case sections
  Alpine.data('SectionCaseTestimonials', SectionCaseTestimonials)
  Alpine.data('SectionCaseContent', SectionCaseContent)

  // About sections
  Alpine.data('SectionAboutMove', SectionAboutMove)
  Alpine.data('SectionAboutCare', SectionAboutCare)
  Alpine.data('SectionAboutWe', SectionAboutWe)

  // Team sections
  Alpine.data('SectionTeamNumbers', SectionTeamNumbers)
}
