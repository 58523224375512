import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { defineComponent } from '~/scripts/utils/alpine'
import { useStore } from '~/scripts/composables/useStore'

export default defineComponent(() => ({
  isActive: false,
  rotateHue: false,
  init() {
    const store = useStore(this)

    ScrollTrigger.create({
      trigger: this.$el,
      start: 'top center',
      end: 'bottom center',
      onToggle: ({ isActive }) => {
        this.isActive = isActive
        store.ui.setTheme(isActive ? 'ecru-white-100' : 'default')
      },
      onEnter: () => {
        this.rotateHue = true
      },
      onLeaveBack: () => {
        this.rotateHue = false
      },
    })
  },
}))
