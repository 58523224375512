import { gsap } from 'gsap'
import { defineComponent } from '~/scripts/utils/alpine'
import screens from '~/config/screens.json'

export default defineComponent(() => ({
  slideIndex: 0,
  countGridItems: 0,
  get currentSlide() {
    return gsap.utils.wrap(this.sequence, this.slideIndex)
  },
  get sequence(): number[] {
    // @ts-expect-error - property coming from GridSlideshow
    return this.gridItemSequence
  },
  init() {
    if (!this.$el.parentNode) return
    const parentChildIndex = Array.prototype.indexOf.call(
      this.$el.parentNode.children,
      this.$el,
    )
    this.slideIndex = parentChildIndex
    this.countGridItems = this.$el.parentNode.children.length

    this.$watch('current', (value: number) => {
      if (parentChildIndex === value) {
        // This is the current grid item, let's move to the next slide (+= number of grid items)
        this.slideIndex += this.countGridItems
      }
    })

    // The number of grid items change on lg+ screens so we need to refresh the sequence
    window
      .matchMedia(`(min-width: ${screens.lg})`)
      .addEventListener('change', () => {
        if (!this.$el.parentNode) return
        this.countGridItems = this.$el.parentNode.children.length
        this.slideIndex = parentChildIndex
      })
  },
}))
