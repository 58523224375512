/**
 * Calculates the Euclidean distance between two points in 2D space.
 * @param x1 - X-coordinate of the first point
 * @param y1 - Y-coordinate of the first point
 * @param x2 - X-coordinate of the second point
 * @param y2 - Y-coordinate of the second point
 * @returns The distance between the two points
 */
export function getDistance(x1: number, y1: number, x2: number, y2: number) {
  return Math.hypot(x2 - x1, y2 - y1)
}

/**
 * Checks if a number is close enough to another number.
 * @param current - The current value
 * @param target - The target value
 * @param precision - The precision interval
 * @returns Whether the current value is close enough to the target value
 */
export function isAtTarget(current: number, target: number, precision = 0.01) {
  return current < target + precision && current > target - precision
}
