import Lenis from '@studio-freight/lenis'

interface LenisOptions {
  wrapper?: Window | HTMLElement
  content?: HTMLElement
  wheelEventsTarget?: Window | HTMLElement
  eventsTarget?: Window | HTMLElement
  smoothWheel?: boolean
  smoothTouch?: boolean
  syncTouch?: boolean
  syncTouchLerp?: number
  __iosNoInertiaSyncTouchLerp?: number
  touchInertiaMultiplier?: number
  duration?: number
  easing?: (t: number) => number
  lerp?: number
  infinite?: boolean
  orientation?: 'vertical' | 'horizontal'
  gestureOrientation?: 'vertical' | 'horizontal' | 'both'
  touchMultiplier?: number
  wheelMultiplier?: number
  normalizeWheel?: boolean
  autoResize?: boolean
}

interface UseLenisOptions {
  prevent?: string[]
  useTicker?: (lenis: Lenis) => void
}

/**
 * Use Lenis (smooth scroll)
 * @see https://github.com/studio-freight/lenis
 */
export function useLenis(options: UseLenisOptions & LenisOptions = {}) {
  const { prevent, useTicker, ...lenisOptions } = options
  if (prevent?.length) {
    const elements = document.querySelectorAll<HTMLElement>(prevent.join(','))
    for (const element of elements) {
      element.dataset.lenisPrevent = ''
    }
  }

  const lenis = new Lenis(lenisOptions)
  window.lenis = lenis

  if (useTicker) {
    useTicker(lenis)
  } else {
    function raf(time: number) {
      lenis.raf(time)
      requestAnimationFrame(raf)
    }
    requestAnimationFrame(raf)
  }

  return lenis
}
