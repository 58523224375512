import { gsap } from 'gsap'
import { defineComponent } from '~/scripts/utils/alpine'

export default defineComponent(() => ({
  init() {
    const video = this.$el.querySelector('video')
    const { sound } = this.$refs

    gsap.to(this.$el.children[0], {
      y: '50%',
      ease: 'none',
      scrollTrigger: {
        trigger: this.$el,
        start: 'top top',
        end: 'bottom top',
        scrub: true,
        onLeave: () => {
          if (video) {
            video.pause()
          }
        },
        onEnterBack: () => {
          if (video) {
            video.play()
          }
        },
      },
    })

    if (sound && video) {
      sound.addEventListener('click', () => {
        sound.classList.toggle('is-active')
        video.muted = !video.muted
      })
    }
  },
}))
