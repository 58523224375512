import { gsap } from 'gsap'
import { defineComponent } from '~/scripts/utils/alpine'

export default defineComponent(() => ({
  progress: 0,
  init() {
    // @ts-expect-error this.scrollTween comes from the parent component (SectionCaseContent)
    const scrollTween: gsap.core.Animation | undefined = this.scrollTween

    const options: ScrollTrigger.StaticVars = scrollTween
      ? { containerAnimation: scrollTween }
      : { scroller: this.$refs.contentSticky }

    gsap.to(this.$root.children[0], {
      x: () => this.$root.clientWidth - window.innerWidth,
      ease: 'none',
      scrollTrigger: {
        ...options,
        trigger: this.$root,
        horizontal: true,
        start: 'left left',
        end: 'right right',
        scrub: true,
        invalidateOnRefresh: true,
        onUpdate: ({ progress }) => {
          this.progress = progress
        },
      },
    })
  },
}))
