import { ofetch } from 'ofetch'

export function useTeamApi() {
  return {
    /**
     * Get all team members
     * @returns Members of the team
     */
    getTeamMembers() {
      return ofetch<TeamMember[]>('/wp-json/egg/team-members')
    },
    /**
     * Get a team member by country
     * @param country Country ID
     * @returns Member of the team from the country
     */
    getTeamMemberByCountry(country: number): Promise<TeamMember | undefined> {
      return ofetch<TeamMember[]>('/wp-json/egg/team-members', {
        query: { country },
      })
    },
  }
}
