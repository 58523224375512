import SplitType from 'split-type'

export interface UseSplitTypeOptions {
  /**
   * Animation duration in ms
   *
   * @default 500
   */
  duration?: number | ((line: HTMLElement, index: number) => number)

  /**
   * Stagger delay in ms
   *
   * @default 50
   */
  stagger?: number | ((line: HTMLElement, index: number) => number)

  /**
   * Easing function
   *
   * @default 'ease-in-out'
   */
  easing?: string | ((line: HTMLElement, index: number) => string)
}

/**
 * Use SplitType animation (enter )
 * @param target - Target element
 * @param options - Options
 * @returns - SplitType object
 */
export function useSplitType(
  target: HTMLElement,
  options: UseSplitTypeOptions = {},
) {
  const stagger = options.stagger ?? 50
  const duration = options.duration ?? 500
  const easing = options.easing ?? 'ease-in-out'

  let split: SplitType | undefined

  async function update(keyframes: Keyframe[], enter?: boolean) {
    if (split) {
      // Cancel any ongoing animations
      if (split.lines) {
        for (const line of split.lines) {
          for (const animation of line.getAnimations()) animation.cancel()
        }
      }
      split.revert()
    }

    split = SplitType.create(target, { types: 'lines' })

    if (!split.lines) {
      // Nothing to animate
      if (enter) revert(enter ? 'inherit' : '')
      return
    }

    const animations = split.lines.map((line: HTMLElement, index: number) => {
      // Make sure the line is visible while animating
      for (const keyframe of keyframes) keyframe.visibility = 'visible'

      const animation = line.animate(keyframes, {
        duration:
          typeof duration === 'function' ? duration(line, index) : duration,
        delay:
          typeof stagger === 'function'
            ? stagger(line, index)
            : stagger * index,
        easing: typeof easing === 'function' ? easing(line, index) : easing,
        fill: 'forwards',
      })
      return animation.finished
    })

    try {
      await Promise.all(animations)
      // Animations done
      revert(enter ? 'inherit' : '')
    } catch {
      // Animations canceled
    }
  }

  function enter(keyframes?: Keyframe[]) {
    return update(
      keyframes ?? [
        {
          transform: 'translate3d(0, 60%, 0)',
          opacity: 0,
        },
        {
          transform: 'translate3d(0, 0, 0)',
          opacity: 1,
        },
      ],
      true,
    )
  }

  function leave(keyframes?: Keyframe[]) {
    return update(
      keyframes ?? [
        {
          transform: 'translate3d(0, 0, 0)',
          opacity: 1,
        },
        {
          transform: 'translate3d(0, -60%, 0)',
          opacity: 0,
        },
      ],
    )
  }

  function revert(visibility = '') {
    if (split) {
      split.revert()
      split = undefined
    }
    target.style.visibility = visibility
  }

  return {
    enter,
    leave,
    revert,
  }
}
